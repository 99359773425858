//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { RotatingText } from '@fc/angie-ui';

export default {
  name: 'SplitTextView',
  components: {
    RotatingText,
  },
  props: {
    splitTextData: {
      type: Array,
      required: true,
      validator(data) {
        return data.length === 2 && data.every(entry => entry.position && entry.bgColor && entry.textColor && entry.copy);
      },
    },
    ignoreMediaQueryFor: {
      type: String,
      default: '',
    },
    isRightTextRotate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayRotatingText(index) {
      return !!index && this.isRightTextRotate;
    },
    isRightText(index) {
      return !index;
    },
  },
  computed: {
    splitTextViewClass() {
      return this.ignoreMediaQueryFor ? `split-text-view--ignore-${this.ignoreMediaQueryFor}` : '';
    },
    splitTextViewInner() {
      return (position) => `split-text-view__${position}-inner fc-flex`;
    },
    splitTextViewText() {
      return (position) => `split-text-view__${position}-text fc-title`;
    },
    getFullText() {
      return this.isRightTextRotate ? `${this.splitTextData[0].copy} ${this.splitTextData[1].copy.join(', ')}` : this.splitTextData.map(data => data.copy).join(' ');
    },
  },
};
