




import { defineComponent } from '@vue/composition-api';
import SplitTextView from '~/components/SplitTextView.vue';
import { red, blue, eggShell } from '@fc/angie-ui/dist/styles/_js_exports.scss';

const ROTATING_WORD_LIST = [
  'interactive',
  'educational',
  'competitive',
  'rewarding',
  'connected',
  'fun',
  'empowering',
  'for everyone',
  'at home',
  'smart',
  'boxing',
  'kickboxing',
  'game-changing',
];

export default defineComponent({
  name: 'RotatingTextView',
  components: {
    SplitTextView,
  },
  setup() {
    const splitTextData = [
      {
        position: 'left', bgColor: red, textColor: eggShell, copy: 'FightCamp is',
      },
      {
        position: 'right', bgColor: blue, textColor: red, copy: ROTATING_WORD_LIST,
      },
    ];

    return { splitTextData };
  },
});
